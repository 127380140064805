exports.components = {
  "component---src-components-templates-pribeh-detail-index-js": () => import("./../../../src/components/templates/pribeh-detail/index.js" /* webpackChunkName: "component---src-components-templates-pribeh-detail-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bankovani-index-js": () => import("./../../../src/pages/bankovani/index.js" /* webpackChunkName: "component---src-pages-bankovani-index-js" */),
  "component---src-pages-dornova-metoda-index-js": () => import("./../../../src/pages/dornova-metoda/index.js" /* webpackChunkName: "component---src-pages-dornova-metoda-index-js" */),
  "component---src-pages-homeopatie-index-js": () => import("./../../../src/pages/homeopatie/index.js" /* webpackChunkName: "component---src-pages-homeopatie-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-magnetoterapie-index-js": () => import("./../../../src/pages/magnetoterapie/index.js" /* webpackChunkName: "component---src-pages-magnetoterapie-index-js" */),
  "component---src-pages-o-nas-index-js": () => import("./../../../src/pages/o-nas/index.js" /* webpackChunkName: "component---src-pages-o-nas-index-js" */),
  "component---src-pages-osteodynamika-index-js": () => import("./../../../src/pages/osteodynamika/index.js" /* webpackChunkName: "component---src-pages-osteodynamika-index-js" */),
  "component---src-pages-pribehy-index-js": () => import("./../../../src/pages/pribehy/index.js" /* webpackChunkName: "component---src-pages-pribehy-index-js" */)
}

